import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { ExerciseTextArea, Typeform, Vimeo } from "@swizec/gatsby-theme-course-platform";
export const title = "Find Your Idea self-paced workshop";
export const description = "";
export const image = "/chapter_headers/serverless-elements.png";
export const _frontmatter = {};
const layoutProps = {
  title,
  description,
  image,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "find-your-idea-self-paced-workshop"
    }}>{`Find Your Idea self-paced workshop`}</h1>
    <p>{`Work through the exercises and learn the framework to find, validate, and qualify your ideas. Use pen&paper or the provided textarea.`}</p>
    <p>{`Don't worry about sharing your data. The textareas only save to your browser :)`}</p>
    <Vimeo id="419789003" mdxType="Vimeo" />
    <h2 {...{
      "id": "the-personas"
    }}>{`The personas`}</h2>
    <p>{`Give advice to these 4 fictional personas and by extension yourself.`}</p>
    <Vimeo id="419789079" mdxType="Vimeo" />
    <h2 {...{
      "id": "why-are-you-building"
    }}>{`Why are you building?`}</h2>
    <p>{`The first question to ask yourself is `}<em parentName="p">{`"Why am I looking for an idea?"`}</em></p>
    <Vimeo id="419789161" mdxType="Vimeo" />
    <ExerciseTextArea name="why-building" mdxType="ExerciseTextArea" />
    <Vimeo id="419789271" mdxType="Vimeo" />
    <h2 {...{
      "id": "ideas-are-problems"
    }}>{`Ideas are problems`}</h2>
    <p>{`Every problem is an idea in disguise.`}</p>
    <Vimeo id="419789339" mdxType="Vimeo" />
    <ExerciseTextArea name="ideas-problems" mdxType="ExerciseTextArea" />
    <Vimeo id="419789436" mdxType="Vimeo" />
    <h2 {...{
      "id": "the-adjacent-possible"
    }}>{`The adjacent possible`}</h2>
    <p>{`The best ideas come from the adjacent possible - a shadow future of stuff that's just becoming viable after being very hard for years.`}</p>
    <Vimeo id="419789508" mdxType="Vimeo" />
    <ExerciseTextArea name="adjacent-possible" mdxType="ExerciseTextArea" />
    <Vimeo id="419789667" mdxType="Vimeo" />
    <h2 {...{
      "id": "ideas-need-validation"
    }}>{`Ideas need validation`}</h2>
    <p>{`An idea is nothing without validation`}</p>
    <Vimeo id="419789714" mdxType="Vimeo" />
    <ExerciseTextArea name="validation" mdxType="ExerciseTextArea" />
    <Vimeo id="419789938" mdxType="Vimeo" />
    <h2 {...{
      "id": "whos-it-for"
    }}>{`Who's it for?`}</h2>
    <p>{`After validation, every idea needs an audience and a plan to reach it.`}</p>
    <Vimeo id="419789976" mdxType="Vimeo" />
    <ExerciseTextArea name="audience" mdxType="ExerciseTextArea" />
    <Vimeo id="419790133" mdxType="Vimeo" />
    <h2 {...{
      "id": "why-you"
    }}>{`Why you?`}</h2>
    <p>{`Ideas need to match their authors. Do you have something new to contribute?`}</p>
    <Vimeo id="419790200" mdxType="Vimeo" />
    <ExerciseTextArea name="why-you" mdxType="ExerciseTextArea" />
    <Vimeo id="419790288" mdxType="Vimeo" />
    <h2 {...{
      "id": "think-like-an-investor"
    }}>{`Think like an investor`}</h2>
    <p>{`You've got the idea, the validation, the audience, and you know it fits. Is it worth it?`}</p>
    <Vimeo id="419790380" mdxType="Vimeo" />
    <ExerciseTextArea name="why-you" mdxType="ExerciseTextArea" />
    <Vimeo id="419790654" mdxType="Vimeo" />
    <h2 {...{
      "id": "fin"
    }}>{`Fin`}</h2>
    <p>{`The main things I hope you take away :)`}</p>
    <Vimeo id="419790701" mdxType="Vimeo" />
    <h2 {...{
      "id": "did-you-enjoy-the-self-paced-workshop-lemme-know"
    }}>{`Did you enjoy the self-paced workshop? Lemme know`}</h2>
    <p><a parentName="p" {...{
        "href": "https://swizecteller.typeform.com/to/QlsZWr"
      }}>{`Your feedback really helps ❤️`}</a></p>
    <Typeform url="https://swizecteller.typeform.com/to/QlsZWr" mdxType="Typeform" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      